
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import apiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    interface IState {
        list: any,
        searches: any,
        total: any,
        editData:any,
        visable:any
    }
    export default ({
        name: 'ArticleList',
        components: {},
        setup() {
            const state: IState = reactive({
                list: [],
                searches: {
                    page_no: PageUtil.pageNo,
                    page_size: PageUtil.pageSize,
                },
                total: 0,
                editData:"",
                visable:false
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center'
                },
                {
                    title: '商品名称',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center'
                },
                {
                    title: '图片',
                    dataIndex: 'image',
                    key: 'image',
                    align: 'center'
                },
                {
                    title: '最高价',
                    dataIndex: 'max_price',
                    key: 'max_price',
                    align: 'center'
                },
                {
                    title: '最低价',
                    dataIndex: 'min_price',
                    key: 'min_price',
                    align: 'center'
                },
                {
                    title: '分佣百分比（%）',
                    dataIndex: 'ratio',
                    key: 'ratio',
                    align: 'center'
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                    align: 'center'
                },
                {
                     title: "操作",
                     key: "action",
                     width: 150,
                },
                
            ];
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page_no,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiMember.distributionList(params)
                if (res.error_code == 0) {
                    const data:any = res.data
                    state.list = data.lists
                    state.total = data.count
                } else {
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.page_size = pages.pageSize
                state.searches.page_no = pages.current
                getList()
            };

            const edit = (val) => {
                state.editData = {
                    "id":val.id,
                    "goods_id":val.goods_id,
                    "item_id":'',
                    "ratio":val.ratio,
                    "sort":val.sort,
                }
                state.visable = true
            }

            const handleOk = async () => {
                const params = { ...state.editData };
                const res = await apiMember.distributionEdit(params);
                if (res.error_code == 0) {
                    message.success(res.msg);
                    state.visable = false;
                    getList();
                } else {
                    message.error(res.msg);
                }
            };

            const onDel = async (val) => {
                const params = { id: val.id };
                const res = await apiMember.distributionDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }else{
                        message.error(res.msg)
                    }
                }
            };


            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                onDel,
                edit,
                handleOk,
                handleTableChange,
                pagination,
                columns
            }
        }
    })
